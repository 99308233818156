const creditCardProviders = {
  jcb: 'jcb',
  visa: 'visa',
  amex: 'amex',
  diners: 'diners',
  maestro: 'maestro',
  unknown: 'unknown',
  discover: 'discover',
  mastercard: 'mastercard',
};

export { creditCardProviders };
